var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
}); // adapted from https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md

var detectPassiveEvents = {
  update: function update() {
    if (typeof window !== "undefined" && typeof window.addEventListener === "function") {
      var passive = false;
      var options = Object.defineProperty({}, "passive", {
        get: function get() {
          passive = true;
        }
      }); // note: have to set and remove a no-op listener instead of null
      // (which was used previously), becasue Edge v15 throws an error
      // when providing a null callback.
      // https://github.com/rafgraph/detect-passive-events/pull/3

      var noop = function noop() {};

      window.addEventListener("testPassiveEventSupport", noop, options);
      window.removeEventListener("testPassiveEventSupport", noop, options);
      detectPassiveEvents.hasSupport = passive;
    }
  }
};
detectPassiveEvents.update();
exports.default = detectPassiveEvents;
export default exports;
export const __esModule = exports.__esModule;